import React from 'react';
import Lottie from 'react-lottie';
import animationData from './cnd.json';
import './Logo.css';

export default class Logo extends React.Component {
  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {}
    };

    return (
      <div className="logo">
        <Lottie options={defaultOptions} width={120} height={110} />
      </div>
    );
  }
}
