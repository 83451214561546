import React from 'react';
import axios from 'axios';
import './Form.css';

interface FormProps {}

interface FormState {
  code: string;
  error: any;
  isLoading: boolean;
  isLocked: boolean;
}

export default class Form extends React.Component<FormProps, FormState> {
  constructor(props: FormProps) {
    super(props);
    this.state = {
      code: '',
      error: false,
      isLoading: false,
      isLocked: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChange(e: any) {
    this.setState({
      code: e.target.value.toUpperCase(),
      error: false
    });
  }

  submitForm(e: any) {
    e.preventDefault();
    if (this.state.code.length === 5 && !this.state.isLoading) {
      this.setState({
        error: false,
        isLoading: true
      });
      axios
        .post('https://api.dafgard2019.se/auth/login', {
          key: this.state.code.toUpperCase()
        })
        .then(res => {
          if (res.data.error) {
            this.setState({ error: res.data.error });
            this.setState({ isLoading: false });
            return;
          }
          document.cookie =
            'CloudFront-Policy=' +
            res.data.cookies['CloudFront-Policy'] +
            ';domain=dafgard2019.se;path=/;secure';
          document.cookie =
            'CloudFront-Key-Pair-Id=' +
            res.data.cookies['CloudFront-Key-Pair-Id'] +
            ';domain=dafgard2019.se;path=/;secure';
          document.cookie =
            'CloudFront-Signature=' +
            res.data.cookies['CloudFront-Signature'] +
            ';domain=dafgard2019.se;path=/;secure';
          window.location.replace(
            'https://guest.dafgard2019.se/?token=' + res.data.token
          );
        })
        .catch(err => {
          console.dir(err);
          if (err.response.status === 429) {
            this.setState({ isLocked: true });
          }
          this.setState({
            code: '',
            error: err.response.data.error
              ? err.response.data.error
              : 'Det uppstod ett oväntat fel',
            isLoading: false
          });
        });
    }
  }

  render() {
    return (
      <form>
        <p>Logga in som bröllopsgäst</p>
        {!this.state.isLocked && (
          <div className="form">
            <input
              type="text"
              placeholder="Ange kod"
              maxLength={5}
              onChange={this.handleChange}
            />
          <button onClick={this.submitForm}>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="arrow-right"
                 className="svg-inline--fa fa-arrow-right fa-w-14" role="img" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 448 512">
              <path fill="currentColor"
                    d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z"></path>
            </svg>
          </button>
        </div>
        )}
        {this.state.isLoading && <p className="loading">Verifierar...</p>}
        {this.state.error && <p className="error">{this.state.error}</p>}
      </form>
    );
  }
}
